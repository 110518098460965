import React from 'react';
import HeaderBar from '../components/HeaderBar';
import { withRouter } from 'react-router';
import { firestore, functions, storage } from '../firebase/firebase.utils';
import moment from 'moment';
import ls from 'local-storage';

class AgreementPage extends React.Component {
    constructor (props) {
        super(props);

        // If no user ID is set, no one is logged in
        if (!ls.get('uid')) {
            // Redirect the user back to the login page
            this.props.history.replace('/');
        }

        // Store global references to the firestore collections
        this.agreementRef = firestore.collection('agreements');
        this.projectRef = firestore.collection('projects');
        this.clientRef = firestore.collection('users');

        // To look up other data by reference, we need a reference to the user
        this.advertiserRef = firestore.collection('users').doc(ls.get('uid'));

        // Define our cloud functions
        this.advertiserThankYouEmail = functions.httpsCallable('advertiserThankYouEmail');
        this.copyAdToFinalFolder = functions.httpsCallable('copyAdToFinalFolder');

        this.state = {
            // step: 2,s
            stepOneComplete: false,
            stepTwoComplete: false,
            stepThreeComplete: false,
            everythingComplete: false,
            approvingProof: false,
            rejectingProof: false,
            currentStep: 1,
            agreements: [],
            id: 'G73Z9jAf8V8vTzLl96VG',
            rejectedReasonAdvertiser: '',
            revisionImages: [],
            selectedAgreementIndex: 0,
            name: '',
            ads: [],

            advertiserEmail: 'dev@lunarcow.com',
            projectName: '2020 Visitor Guide',
            companyName: 'Destination Irvine',

            copy: '',
            creativeDirection: '',
            logo: '',
            images: [],

            // agreement: {
            // },
            project: {
                n: '2020 Project Name'
            },
            client: {
                id: '1',
                n: 'Client Name'
            },
            size: {
            },
            rep: {
                id: '1',
                n: 'Ed Asper',
                e: 'edasper@lunarcow.com',
                oe: '131'
            },
            designer: {
                id: '1',
                n: 'Dave Potokar',
                e: 'dave@lunarcow.com',
                oe: '126'
            },

            approvedBy: '',
            approvedOn: '',
            approvedTosOn: '',
            // selectedAgreement: null,
            // selectedAgreementData: null,
            // client: {
            //     name: 'Buena Park',
            //     logo: 'clients/buena-park/logos/medium.png',
            //     projectName: '2019 Visitor Guide'
            // },
            //agreements: [],
            success: '',
            error: ''
        };
    }

    componentDidMount () {
        // console.log('User ID', ' => ', ls.get('uid'));
        try {
            // this.agreementRef
            firestore
                .collection('agreements')
                .where('advertiser', '==', this.advertiserRef)
                .get()
                .then(snapshot => {
                    // Load all the agreements for the logged in user
                    const agreements = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                    console.log('agreements', ' => ', agreements);

                    // Set the agreement array in the state
                    this.setState(prevState => ({ agreements }));

                    // If there is only one agreement in the system (which is usually the case)
                    if (agreements.length == 1) {
                        // Populate the state with all the data from the only agreement
                        this.setState({ ...agreements[0] }, () => this.setStep());
                    }
                })
                .catch(err => console.log('Error loading the agreements for the advertiser', ' => ', err));
        } catch (error) {
            console.log('error in mounting try/catch', ' => ', error);
        }

        // TODO: Here is where we set the currentStep in the state object
        // --------------------------------------------------------------

        // TODO: Load all agreements for the logged in user

        // If the agreement count is 0, show the no NoAds component

        // If the agreement count is 1, load the 1/2/3 process and figure out where they are in the process
        /*
            if agreement.ads.length == 0,
                - all three steps are false, don't update complete steps
                - set step to 1
            if agreement.ads.length > 3 ... need to figure out the logic for when to show a payment page (TODO)
            if agreement.ads.length >= 1 && agreement.ads.length <= 3,
                - if (aa.app) -> go to step 3
                - else:
                    - set the step to 2
                    - show the last ad in the array; if (ads[index].url) :  otherwise show [proof pending]
                        -
                        - Two buttons; if (da.app) : enable buttons, otherwise make them disabled and put a note
                            - Approve
                                - Mark aa.app (advertiser approved) to true in firestore
                                - Increase the step to 3 and setstate of step 2 to complete
                            - Reject
                                - Must fill out text area for changes
                                - File upload option for new content

                        - give the option to approve or reject. Put a text input next to the reject option
        */

        // If the agreement count is > 1, load the AgreementSelector component

        /*
        */

        // console.log('component mount called');
    }

    // If there are multiple agreements, set one from the dropdown
    setAgreement = e => {
        e.preventDefault();
        e.persist();

        // Grab the index of the selected agreement. We offset the index by one to
        // allow for a "please select your agreement" at index zero, so we need
        // to subtract one here
        const agreement = (e.target.value - 1);

        // Paranoia check to make sure the index exists
        if (this.state.agreements && this.state.agreements[agreement]) {
            // Populate the state with the data from the selected agreement
            this.setState({ ...this.state.agreements[agreement] }, () => this.setStep());
        }
    }

    // Set the proper step for the user based on selected agreement's data
    setStep = () => {
        // Prevent the page from performing a hard refresh
        // e.preventDefault();

        const { ads, approvedTosOn, approvedBy } = this.state;

        // Create our conditions for each step in advance to keep the conditionals clean
        const stepOneComplete = (ads && ads.length >= 1);
        const stepTwoComplete = (stepOneComplete && approvedBy);
        const stepThreeComplete = (stepTwoComplete && approvedTosOn);

        // If there's at least one ad generated, there's an ad approval,
        // and the TOS was accepted, they're completely done
        if (stepThreeComplete) {
            this.setState({
                stepOneComplete: true,
                stepTwoComplete: true,
                stepThreeComplete: true,
                everythingComplete: true,
                currentStep: 0
            });
        }
        // If they've completed the requirements for step two, put them on step three
        else if (stepTwoComplete) {
            this.setState({
                stepOneComplete: true,
                stepTwoComplete: true,
                stepThreeComplete: false,
                everythingComplete: false,
                currentStep: 3
            });
        }
        // If they've completed the requirements for step one, put them on step two
        else if (stepOneComplete) {
            this.setState({
                stepOneComplete: true,
                stepTwoComplete: false,
                stepThreeComplete: false,
                everythingComplete: false,
                currentStep: 2
            });

            // Check whether ad an proof is available for the advertiser to review
            if (ads && ads[ads.length - 1].adUrl) {
                // Only move the advertiser to step two if there is a proof
                this.setState({ currentStep: 2 });
            }
        }
        // They haven't done anything yet, leave them on step one
        else {
            this.setState({
                stepOneComplete: false,
                stepTwoComplete: false,
                stepThreeComplete: false,
                everythingComplete: false,
                currentStep: 1
            });
        }
    };

    // Upload the camera ready file when they choose it in the file window
    uploadCameraReadyArt = e => {
        if (e.target.files[0]) {
            // TODO: Disable button and show message / progress meter

            const img = e.target.files[0];
            const uploadTask = storage.ref(`agreements/${this.state.id}/${img.name}`).put(img);

            uploadTask.on(
                "state_changed",
                snapshot => {
                    // progress function ...
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    console.log('progress', ' => ', progress);
                    this.setState({ progress });
                },
                error => {
                    // Error function ...
                    console.log(error);
                },
                () => {
                    // complete function ...
                    storage
                        .ref(`agreements/${this.state.id}`)
                        .child(img.name)
                        .getDownloadURL()
                        .then(url => {
                            console.log('url', ' => ', url);

                            const ads = [...this.state.ads, { cameraReadyFile: url }];

                            this.agreementRef
                                .doc(this.state.id)
                                .update({ ads })
                                .then(() => this.setState({ stepTwoMessage: 'Your camera ready art has been submitted successfully. You will be notified via email after the design team has reviewed your artwork.', stepOneComplete: true }))
                                .catch(() => this.setState({ error: 'There was a problem setting the camera ready file' }));
                        });
                }
            );
        } else {
            console.log('No file selected');
        }
    };

    // Upload the camera ready file when they choose it in the file window
    uploadLogo = e => {
        // Check to make sure a file was selected
        if (e.target.files[0]) {
            const img = e.target.files[0];
            const uploadTask = storage.ref(`agreements/${this.state.id}/${img.name}`).put(img);

            uploadTask.on(
                "state_changed",
                snapshot => {
                    // progress function ...
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // this.setState({ progress });
                },
                error => {
                    // Error function ...
                    console.log(error);
                },
                () => {
                    // complete function ...
                    storage
                        .ref(`agreements/${this.state.id}`)
                        .child(img.name)
                        .getDownloadURL()
                        .then(logo => {
                            // Update the logo in the state
                            this.setState(() => ({ logo }));
                        });
                }
            );
        } else {
            console.log('No file selected');
        }
    };

    // Upload the camera ready file when they choose it in the file window
    uploadImages = e => {
        // Check to make sure a file was selected
        if (e.target.files) {
            const imgs = e.target.files;

            if (imgs.length > 0) {
                // console.log(imgs);

                for (let i = 0; i < imgs.length; i++) {
                    const img = imgs[i];
                    const uploadTask = storage.ref(`agreements/${this.state.id}/${img.name}`).put(img);

                    uploadTask.on(
                        "state_changed",
                        snapshot => {
                            // progress function ...
                            const progress = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );

                            // this.setState({ progress });
                        },
                        error => {
                            // Error function ...
                            console.log(error);
                        },
                        () => {
                            // complete function ...
                            storage
                                .ref(`agreements/${this.state.id}`)
                                .child(img.name)
                                .getDownloadURL()
                                .then(image => {
                                    // Update the images in the state
                                    this.setState(prevState => ({ images: prevState.images.length > 0 ? [...prevState.images, image] : [image] }));
                                });
                        }
                    );
                }
            }
        } else {
            console.log('No files selected');
        }
    };

    // Upload any content, and save the data to firebase for the new ad
    submitAdComponents = e => {
        // Prevent the page from performing a hard refresh
        e.preventDefault();

        // Extract the ad components from the state
        const { copy, creativeDirection, logo, images } = this.state;

        // TODO: Validate at least the required fields are populated

        // TODO: Error check for copy/direction length

        console.log('copy =>', copy);
        console.log('creativeDirection =>', creativeDirection);
        console.log('logo =>', logo);
        console.log('images =>', images);

        // Get the ads from the state and insert a new ad with the specified components
        const ads = [...this.state.ads, { copy, creativeDirection, logo, images }];

        // Update the document with the new components
        this.agreementRef
            .doc(this.state.id)
            .update({ ads })
            // .update({ copy, creativeDirection, logo, images })
            .then(() => this.setState({
                stepTwoMessage: 'Your ad components have been submitted successfully. You will be notified via email after the design team has a proof available for your review.',
                copy: '',
                creativeDirection: '',
                logo: [],
                images: []
            }))
            .catch(() => this.setState({ error: 'There was a problem submitting the ad components' }));
    };

    // The advertiser doesn't like the ad. Submit their revision request
    // to firebase for the designers to review it
    requestRevision = () => {
        // const {  } = this.state;
        const rejectedOn = moment().format();
        const { rejectedReasonAdvertiser, revisionImages } = this.state;

        // TODO: Disable button and show message

        // TODO: Get the list of ads, update only the last one

        // Update the document with the new components
        this.agreementRef
            .doc(this.state.id)
            .update({ rejected: true, rejectedOn, rejectedReasonAdvertiser, revisionImages })
            .then(() => this.setState({
                rejectingProof: false,
                rejectedReasonAdvertiser: '',
                stepTwoMessage: 'Your revision request has been submitted successfully. You will be notified via email when your changes can be reviewed',
            }))
            .catch(() => this.setState({ error: 'There was a problem requesting a change' }));
    };

    // Approve the advertisement
    approveAd = e => {
        e.preventDefault();

        const { approvedBy } = this.state;
        const approvedOn = moment().format();

        // TODO: Get the list of ads, update only the last one

        this.agreementRef
            .doc(this.state.id)
            .update({ approvedBy, approvedOn })
            .then(() => this.setState({
                approvedBy,
                approvedOn,
                approvingProof: false,
                stepTwoComplete: true,
                currentStep: 3
            }))
            .catch(() => this.setState({ error: 'There was a problem approving the advertisement' }));
    };

    // Approve the terms of service (the last step)
    approveTerms = () => {
        // Capture the current timestamp to update the date fields
        const approvedTosOn = moment().format();

        // Get just the filename from the url
        const filename = this.state.ads[this.state.ads.length - 1].adUrl.split('#').shift().split('?').shift().split('%2F').pop();

        console.log('Project Slug', ' => ', this.state.projectSlug);
        console.log('Filename', ' => ', filename);
        console.log('Source Bucket', ' => ', `agreements/${this.state.id}`);
        console.log('Destination Bucket', ' => ', `projects/${this.state.projectSlug}/final-ads`);

        // TODO: Get the list of ads, update only the last one

        this.agreementRef
            .doc(this.state.id)
            .update({ approvedTosOn })
            .then(() => {
                this.setState({
                    approvedTosOn,
                    stepThreeComplete: true,
                    everythingComplete: true,
                    currentStep: 0
                }, () => {
                    // Move the final ad to the final folder
                    this.copyAdToFinalFolder({
                        srcBucket: `agreements/${this.state.id}`,
                        srcFile: filename,
                        destBucket: `projects/${this.state.projectSlug}/final-ads`,
                        destFile: filename
                    })
                    .then(res => console.log('res', ' => ', res));

                    // Send out the thank you email with their ad
                    this.advertiserThankYouEmail({
                        companyName: this.state.companyName,
                        projectName: this.state.projectName,
                        adSize: this.state.sizeName,
                        email: this.state.advertiserEmail,
                        proofUrl: this.state.ads[this.state.ads.length - 1].adUrl,
                    })
                    .then(res => console.log('res', ' => ', res));
                })
            })
            .catch(() => this.setState({ error: 'There was a problem approving the terms of service' }));
    };

    // Download a copy of their advertisement
    downloadAd = e => {
        // Prevent the page from performing a hard-refresh
        e.preventDefault();

        // TODO: Open their ad based on the url from our S3 space

        console.log('Not Yet Implemented');
    };

    render () {
        return (
            <div>
                <HeaderBar />

                <main className="main-content" style={{ backgroundColor: '#f8f9fa' }}>
                    <section className="section">
                        <div className="container">
                            <header className="section-header">
                                <h2>Advertiser Portal</h2>
                                <hr />
                                {/*
                                    If there are no ads, say something like:
                                    "You do not currently have any advertisements in your account. Please contact your sales representative at Lunar Cow Publishing to fix the problem. Your advertising sales representative is <Rep Name>, and they can be reached at either <Rep Email> or <Phone Num> x<Phone Ext>"

                                    If there is more than one ad, update the following line to say something like:
                                    "You have several active advertisements in your account. To use the portal, you must first select the advertisement you wish to use"

                                    If there is only one ad:
                                    "Follow the steps below to create your advertisement for the {this.state.project.n}"
                                */}

                                {this.state.agreements.length == 0 && <p className="lead">You currently have no advertisements that can be managed through this portal. If you believe this is an error, please reach out to us at (330) 253-9000</p>}

                                {(this.state.agreements.length > 1 && !this.state.sizeName) && <p className="lead">It looks like you have more than one advertisement that needs attention. Please select the advertisement below that you wish to review</p>}

                                {this.state.sizeName && <p className="lead">Follow the steps below to create your advertisement for the {this.state.project.n}</p>}
                            </header>

                            {(this.state.agreements.length > 1 && !this.state.sizeName) &&
                                <div className="row align-items-center">
                                    <div className="col-4 mx-auto">
                                        <select className="form-control" onChange={this.setAgreement} value={this.state.selectedAgreementIndex}>
                                            <option value={0}>Select your advertisement</option>
                                            {this.state.agreements.map((agreement, key) => (
                                                <option key={key} value={(key + 1)}>{agreement.sizeName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            }

                            {this.state.sizeName &&
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <ol className="step">
                                            <li className="step-item">
                                                <div className="step-icon">
                                                    <span className={`${this.state.stepOneComplete ? 'bg-success text-white ' : ''}iconbox`}>
                                                        {this.state.stepOneComplete ? <i className="fa fa-check" /> : '1'}
                                                    </span>
                                                </div>

                                                <div className="step-content">
                                                    <h6 className={`${!(this.state.currentStep == 1) ? 'text-muted' : ''}`}><strong>Create Your Advertisement</strong></h6>

                                                    {/*
                                                        [Each section will display corresponding text on the sidebar]

                                                        Dropdown Select:
                                                            Upload Camera Ready Art
                                                            Design New Ad

                                                        [Once a selection has been made, either show the single
                                                        upload field for art, or multiple fields for the required
                                                        input:
                                                            - Logo
                                                            - Copy
                                                            - Creative Direction
                                                            - Images
                                                        ]

                                                        [Notes for Upload:
                                                            - Accepted File Types:
                                                                - PDF, EPS, TIFF, or JPG
                                                                - 300 DPI
                                                                - CMYK Color Profile
                                                                - Include all fonts
                                                            - Other Notes:
                                                                - MICROSOFT PUBLISHER OR WORD FILES ARE NOT
                                                                ACCEPTABLE FOR PROFESSIONAL PRINT. ALSO,
                                                                WE CANNOT USE IMAGES PULLED FROM THE INTERNET
                                                        ]

                                                        [Notes for Design New:
                                                        ]

                                                        [Once they make their selection and hit upload, it'll mark
                                                        it as complete in the system, and will block the user from
                                                        continuing until a designer takes an action in the portal]
                                                    */}

                                                    {this.state.stepOneComplete &&
                                                        <p>
                                                            You've successfully submitted the data for your advertisement. You will receive a notification via email once the design team has reviewed your submission.
                                                        </p>
                                                    }

                                                    {(this.state.currentStep == 1 && !this.state.stepOneComplete) &&
                                                        <p className="text-muted">
                                                            Here you will upload your camera-ready art, or submit details about your business so that Lunar Cow Publishing can produce your advertisement.
                                                        </p>
                                                    }

                                                    {(this.state.currentStep == 1 && !this.state.stepOneComplete) &&
                                                        <div>
                                                            <p>To create your advertisement, you can either upload your camera-ready artwork, or opt for Lunar Cow Publishing to create your advertisement by providing some basic information about your business.</p>

                                                            <br /><br />

                                                            <div className="custom-file">
                                                                <input type="file" className="custom-file-input" id="customFile" accept=".png, .PNG, .jpg, .JPG, .jpeg, .JPEG, .pdf, .PDF" onChange={this.uploadCameraReadyArt} />
                                                                <label className="custom-file-label" htmlFor="customFile">Upload your Camera Ready Art</label>
                                                            </div>

                                                            {/*
                                                            <p>
                                                                <input type="file" onChange={this.uploadCameraReadyArt} className="form-control" />
                                                            </p>
                                                            */}

                                                            <div className="divider">OR</div>

                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>Copy</label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            rows="2" 
                                                                            value={this.state.copy}
                                                                            onChange={e => this.setState({ copy: e.target.value })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>Creative Direction</label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            rows="2"
                                                                            value={this.state.creativeDirection}
                                                                            onChange={e => this.setState({ creativeDirection: e.target.value })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="custom-file">
                                                                        <input type="file" className="custom-file-input" id="customFile" accept=".png, .PNG, .jpg, .JPG, .jpeg, .JPEG, .pdf, .PDF" onChange={this.uploadLogo} />
                                                                        <label className="custom-file-label" htmlFor="customFile">Upload your logo</label>
                                                                    </div>
                                                                    {this.state.logo && <span className="text-info">Logo has been uploaded</span>}
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="custom-file">
                                                                        <input type="file" className="custom-file-input" id="customFile" accept=".png, .PNG, .jpg, .JPG, .jpeg, .JPEG, .pdf, .PDF" onChange={this.uploadImages} multiple />
                                                                        <label className="custom-file-label" htmlFor="customFile">Upload your images</label>
                                                                    </div>
                                                                    {this.state.images && <span className="text-info">{this.state.images.length} images have been uploaded</span>}
                                                                </div>
                                                            </div>

                                                            <div className="row mt-3">
                                                                <div className="ml-auto">
                                                                    <button className="btn btn-success" onClick={this.submitAdComponents}>Submit Ad Details</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {/*<p>Your advertisement is being processed. You will receive a notification via email when your new proof is available for your review.</p>*/}

                                                    {/*this.state.stepOneComplete ? <p>Your image has been submitted and is currently waiting for designer approval. You will receive an email once a designer has reviewed your advertisement and you are ready to move on to the next step.</p> : ''*/}
                                                </div>
                                            </li>

                                            <li className="step-item">
                                                <div className="step-icon">
                                                    <span className={`${this.state.stepTwoComplete ? 'bg-success text-white ' : ''}iconbox`}>
                                                        {this.state.stepTwoComplete ? <i className="fa fa-check" /> : '2'}
                                                    </span>
                                                </div>

                                                <div className="step-content">
                                                    <h6 className={`${!(this.state.currentStep == 2) ? 'text-muted' : ''}`}><strong>Revise Your Advertisement</strong></h6>

                                                    {this.state.stepTwoComplete &&
                                                        <p>You've successfully approved your advertisement proof.</p>
                                                    }

                                                    {(this.state.currentStep != 2 && !this.state.stepTwoComplete) &&
                                                        <p className="text-muted">
                                                            After your advertisement has been created, you will have the option to either approve your advertisement or request a revision. Each advertisement has <span className="text-danger"><strong>3 revisions</strong></span> included. Additional revisions will incur a one-time fee of $125.
                                                        </p>
                                                    }

                                                    {(this.state.currentStep == 2 && !this.state.stepTwoComplete) &&
                                                        <div>
                                                            {this.state.stepTwoMessage && <p>{this.state.stepTwoMessage}</p>}

                                                            {!this.state.stepTwoMessage &&
                                                                <div>
                                                                    <p>Below you will find your advertisement proof. If everything is to your liking, press the approve button below to accept the advertisement. If there is a problem, selecting the reject button will allow you to enter a message and upload additional artwork if necessary.</p>

                                                                    <p><img src={this.state.ads[this.state.ads.length - 1].adUrl} alt="..." className="img-thumbnail" /></p>

                                                                    {(!this.state.approvingProof && !this.state.rejectingProof) &&
                                                                        <p>
                                                                            <button
                                                                                className="btn btn-success"
                                                                                onClick={e => this.setState({ approvingProof: true })}>
                                                                                Approve
                                                                            </button>

                                                                            <button
                                                                                className="btn btn-danger"
                                                                                onClick={e => this.setState({ rejectingProof: true })}>
                                                                                Reject
                                                                            </button>
                                                                        </p>
                                                                    }

                                                                    {this.state.approvingProof &&
                                                                        <p>
                                                                            <input
                                                                                className="form-control"
                                                                                value={this.state.approvedBy}
                                                                                onChange={e => this.setState({ approvedBy: e.target.value })}
                                                                                placeholder="Your Name"
                                                                            />

                                                                            <button
                                                                                className="btn btn-success"
                                                                                onClick={this.approveAd}>
                                                                                Approve
                                                                            </button>

                                                                            <button
                                                                                className="btn btn-info"
                                                                                onClick={e => this.setState({ approvingProof: false })}>
                                                                                <i className="fa fa-times" />
                                                                            </button>
                                                                        </p>
                                                                    }

                                                                    {this.state.rejectingProof &&
                                                                        <p>
                                                                            <input
                                                                                className="form-control"
                                                                                value={this.state.rejectedReasonAdvertiser}
                                                                                onChange={e => this.setState({ rejectedReasonAdvertiser: e.target.value })}
                                                                                placeholder="Your Comments"
                                                                            />

                                                                            <button
                                                                                className="btn btn-danger"
                                                                                onClick={this.requestRevision}>
                                                                                Reject
                                                                            </button>
                                                                            
                                                                            <button
                                                                                className="btn btn-info"
                                                                                onClick={e => this.setState({ rejectingProof: false })}>
                                                                                <i className="fa fa-times" />
                                                                            </button>
                                                                        </p>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                    {/*<p>Our design team is currently reviewing your revision requests. You will receive a notification via email when your revisions can be reviewed.</p>*/}
                                                </div>
                                            </li>

                                            <li className="step-item">
                                                <div className="step-icon">
                                                    <span className={`${this.state.stepThreeComplete ? 'bg-success text-white ' : ''}iconbox`}>
                                                        {this.state.stepThreeComplete ? <i className="fa fa-check" /> : '3'}
                                                    </span>
                                                </div>

                                                <div className="step-content">
                                                    <h6 className={`${!(this.state.currentStep == 3) ? 'text-muted' : ''}`}><strong>Approve Your Advertisement</strong></h6>

                                                    {this.state.stepThreeComplete &&
                                                        <p>You've successfully approved your advertisement and the terms of service.</p>
                                                    }

                                                    {(this.state.currentStep != 3 && !this.state.stepThreeComplete) &&
                                                        <p className="text-muted">
                                                            Once your advertisement has been created and approved, you will be asked to also review and approve the terms of service.
                                                        </p>
                                                    }

                                                    {(this.state.currentStep == 3 && !this.state.stepThreeComplete) &&
                                                        <div>
                                                            <p>
                                                                This insertion order represents confirmation of the agreement between agency/advertiser and {this.state.companyName}. Payment is due upon receipt of invoice. <strong>ADS NOT PAID IN FULL WILL NOT BE INCLUDED IN THE PUBLICATION. ONLY PREMIUM AD POSITIONS ARE GUARANTEED.</strong>
                                                            </p>
                                                            <p>
                                                                <strong>Due to print and publication deadlines, please be advised that all sales are final and no refunds will be issued. Further, due to publication and distribution of print materials containing information as to all Advertisers, no Advertiser may withdraw from participation. Advertiser agrees to participate for the duration of the publication without exception.</strong>
                                                            </p>
                                                            <p>
                                                                Lunar Cow Publishing and {this.state.companyName} reserve the right to refuse advertisements on the basis of content or quality of production, and assume no liability for statements or claims made in advertising copy. Lunar Cow Publishing and {this.state.companyName} also reserve the right to refuse advertisements that contain what is considered to be false, illegal or libelous information or material that gratuitously offends members of the general public. <strong>Lunar Cow reserves the right to insert our template ad for your ad placement if content is NOT sent within 45 days. Our template ad includes your name, address, phone and website address and is not revisable, except for errors.</strong>
                                                            </p>
                                                            <p>
                                                                <a href="http://www.lunarcow.com/releaseform" target="_blank">
                                                                    View Release Form
                                                                </a>
                                                            </p>
                                                            <button
                                                                className="btn btn-success"
                                                                onClick={this.approveTerms}>
                                                                Approve Terms of Service
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </li>

                                            {/*
                                                Hidden step 4 --

                                                [If all three steps are complete, show a thank you step that it
                                                has been completed and show their finalized advertisement with
                                                an option to download it]
                                            */}
                                            {this.state.everythingComplete &&
                                                <li className="step-item">
                                                    <div className="step-icon">
                                                        <span className="iconbox bg-info text-white">
                                                            <i className="fa fa-rocket" />
                                                        </span>
                                                    </div>

                                                    <div className="step-content">
                                                        <h6><strong>Thank You!</strong></h6>

                                                        <p>
                                                            You advertisement has successfully been submitted and approved for publication in the {this.state.project.n}. You will receive an email confirmation with the details of your advertisement.
                                                        </p>

                                                        <p>
                                                            <a href="" onClick={this.downloadAd}>
                                                                <i className="fa fa-link" /> Download your advertisement
                                                            </a>
                                                        </p>
                                                    </div>
                                                </li>
                                            }
                                        </ol>
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                </main>
            </div>
        );
    }
}

export default withRouter(AgreementPage);
