import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { firestore, auth } from '../../firebase/firebase.utils';
import ls from 'local-storage';

class LoginPage extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor (props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            error: null
        };
    }

    // Check auth when component loads
    componentDidMount () {
        // If a UID is set locally, the user is already logged in
        if (ls.get('uid')) {
            // Redirect them to the dashboard
            this.props.history.replace('/dashboard');
        }
    }

    // Try to login with the credentials provided
    login = e => {
        e.preventDefault();

        const { email, password } = this.state;

        auth
            .signInWithEmailAndPassword(email, password)
            .then(data => {
                // Capture the data returned from a successful login
                const user = data.user;

                // Grab a reference to the logged in user based on their UserID
                const userRef = firestore.doc(`users/${user.uid}`);

                // Set the user ID and other info that comes packaged with auth()
                ls.set('uid', user.uid);
                ls.set('refreshToken', user.refreshToken);
                ls.set('email', user.email);

                // Grab more data on the user and store it in local storage
                userRef.get().then(snapshot => {
                    ls.set('accType', snapshot.data().accType);
                    ls.set('accSubType', snapshot.data().accSubType);

                    // Only update the image if there is one in the system
                    if (snapshot.data().imgProfile) {
                        ls.set('imgProfile', snapshot.data().imgProfile);
                    }

                    ls.set('name', snapshot.data().name);

                    // Clear out the email and password field for security reasons
                    this.setState({ email: '', password: '' });

                    // Only after we get the data do we load the dashboard
                    this.props.history.replace('/dashboard');
                });
            })
            .catch(err => { this.setState({ error: `Invalid login credentials. ${err}` }); });
    };

    // Render the JSX for the login page
    render () {
        return (
            <div className="layout-centered bg-img" style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/images/auth/earth.png)' }}>
                <main className="main-content" style={{ height: '25vh' }}>
                    <img
                        src="https://lunarcow.nyc3.digitaloceanspaces.com/images/logos/observatory/logo.svg"
                        style={{ height: '40vw', paddingTop: '30px' }}
                    />
                </main>

                <main className="main-content" style={{ height: '75vh' }}>
                    <div className="bg-white rounded shadow-7 w-400 mw-100 p-6">
                        <h5 className="mb-7">Sign into the Advertiser Portal</h5>

                        <form>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`form-control ${this.state.error ? 'is-invalid' : ''}`}
                                    name="username"
                                    placeholder="Email"
                                    value={this.state.email || ''}
                                    onChange={e => this.setState({ email: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="password"
                                    className={`form-control ${this.state.error ? 'is-invalid' : ''}`}
                                    name="password"
                                    placeholder="Password"
                                    value={this.state.password || ''}
                                    onChange={e => this.setState({ password: e.target.value })}
                                />
                                {this.state.error &&
                                    <div className="invalid-feedback">{this.state.error}</div>
                                }
                            </div>

                            <div className="form-group flexbox py-3">
                                <NavLink
                                    className="text-muted small-2 ml-auto"
                                    to="/password/reset">
                                    Forgot Password?
                                </NavLink>
                            </div>

                            <div className="form-group">
                                <button
                                    className="btn btn-block btn-primary"
                                    onClick={this.login}>
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        );
    }
}

export default withRouter(LoginPage);
