import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { auth } from '../../firebase/firebase.utils';
import ls from 'local-storage';

class PasswordResetPage extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor (props) {
        super(props);

        this.state = {
            email: '',
            error: null,
            success: null
        };
    }

    // Check auth when component loads
    componentDidMount () {
        // If a UID is set locally, the user is already logged in
        if (ls.get('uid')) {
            // Redirect them to the dashboard
            this.props.history.replace('/dashboard');
        }
    }

    // Try to login with the credentials provided
    resetPassword = e => {
        e.preventDefault();

        const { email } = this.state;

        auth.
            sendPasswordResetEmail(email)
            .then(() => { this.setState({ success: 'If your account was found, you will receive an email to reset your password' }); })
            .catch(() => { this.setState({ success: 'If your account was found, you will receive an email to reset your password' }); });
    };

    // Render the JSX for the login page
    render () {
        return (
            <div className="layout-centered bg-img" style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/images/auth/earth.png)' }}>
                <main className="main-content" style={{ height: '25vh' }}>
                    <img
                        src="https://lunarcow.nyc3.digitaloceanspaces.com/images/logos/observatory/logo.svg"
                        style={{ height: '40vw', paddingTop: '30px' }}
                    />
                </main>

                <main className="main-content" style={{ height: '75vh' }}>
                    <div className="bg-white rounded shadow-7 w-400 mw-100 p-6">
                        <h5 className="mb-7">Reset your password</h5>

                        {this.state.success &&
                            <div className="alert alert-success" role="alert">
                                {this.state.success}
                            </div>
                        }

                        <form>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`form-control ${this.state.error ? 'is-invalid' : ''}`}
                                    name="username"
                                    placeholder="Email"
                                    value={this.state.email || ''}
                                    onChange={e => this.setState({ email: e.target.value })}
                                />
                            </div>

                            <div className="form-group">
                                <button
                                    className="btn btn-block btn-primary"
                                    onClick={this.resetPassword}>
                                    Send Email
                                </button>
                            </div>

                            <div className="form-group flexbox py-3">
                                <NavLink
                                    className="text-muted small-2 ml-auto"
                                    to="/">
                                    Back to Login
                                </NavLink>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        );
    }
}

export default withRouter(PasswordResetPage);
