import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyBvvVwTjvXkvkRIK_Is5kBAaBKnMC_hhAg",
    authDomain: "lunar-cow-publishing.firebaseapp.com",
    databaseURL: "https://lunar-cow-publishing.firebaseio.com",
    projectId: "lunar-cow-publishing",
    storageBucket: "lunar-cow-publishing.appspot.com",
    messagingSenderId: "78561151090",
    appId: "1:78561151090:web:38890e66c3087b32129aeb",
    measurementId: "G-NKWNWKPGFL"
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();

export default firebase;
