import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class NotFoundPage extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor (props) {
        super(props);

        this.state = {
        };
    }

    render () {
        return (
            <div className="container">
                Not Found Page
            </div>
        );
    }
}

export default withRouter(NotFoundPage);
