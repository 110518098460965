import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

// Authentication Pages
import LoginPage from '../pages/auth/LoginPage';
import PasswordResetPage from '../pages/auth/PasswordResetPage';

// Dashboard Page handles the logic for the advertiser portal
import DashboardPage from '../pages/DashboardPage';

// Error Pages
import NotFoundPage from '../pages/errors/NotFoundPage';

// Handle any extra imports / auth
import { createBrowserHistory } from 'history';
import { auth } from '../firebase/firebase.utils';
export const history = createBrowserHistory();

// Our high-order auth check component
import RequireAuth from '../components/auth-middleware/RequireAuth';

class AppRouter extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            currentUser: null
        };

        auth.onAuthStateChanged(currentUser => {
            this.setState({ currentUser });
        });
    }

    render () {
        return (
            <Router history={history}>
                <div>
                    <Switch>
                        <Route path="/" exact component={LoginPage} />
                        <Route path="/password/reset" exact component={PasswordResetPage} />

                        <Route path="/dashboard" exact component={RequireAuth(DashboardPage)} />

                        <Route component={NotFoundPage} />
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default AppRouter;
