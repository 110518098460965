import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { auth } from '../firebase/firebase.utils';
import ls from 'local-storage';

class HeaderBar extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor (props) {
        super(props);
    }

    // Try to log out with firebase
    logout = e => {
        e.preventDefault();

        // Clear out the local storage keys
        ls.clear();

        // Log us out with the firebase auth service
        auth
            .signOut()
            .then(() => { this.props.history.replace('/'); });
    };

    // Handle rendering the JSX for the header bar
    render () {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark">
                <div className="container">
                    <div className="navbar-left mr-4">
                        <button className="navbar-toggler" type="button">&#9776;</button>
                        <Link to="/" className="navbar-brand">
                            <img className="logo-dark" src="https://lunarcow.nyc3.digitaloceanspaces.com/img/logo-dark.png" alt="logo" style={{ height: '39px' }} />
                        </Link>
                    </div>

                    <section className="navbar-mobile">
                        <div className="ml-auto">
                            <a className="btn btn-sm btn-primary" href="#" onClick={this.logout}>Log Out</a>
                        </div>
                    </section>
                </div>
            </nav>
        );
    }
}

export default withRouter(HeaderBar);
