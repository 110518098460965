import React from 'react';
import ls from 'local-storage';

export default function(ComposedComponent) {
    class RequireAuth extends React.Component {
        componentDidMount () { this.checkAuth() }
        componentDidUpdate (nextProps) { this.checkAuth() }
        checkAuth () { if (!ls.get('uid')) this.props.history.push('/') }
        render () { return <ComposedComponent {...this.props} /> }
    }

    return RequireAuth;
}
